@import "../../assets/mixins.scss";

.strokeButton {
  font-size: 16px;
  color: $mainBlue;
  @include InterRegular;
}

.disabled {
  color: $lightBlue;
  pointer-events: none;
}
