@import "../../assets/mixins.scss";

.loader {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;

  i {
    background-color: #fff;
  }
}

.blue {
  i {
    background-color: $mainBlue;
  }
}
