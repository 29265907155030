@import "../../assets/mixins.scss";

.wrapper {
  display: flex;
}

.menu {
  width: 270px;
  text-align: center;
  padding: 15px 0;
  & > svg {
    width: 82px;
  }
}
.main {
  background: #e8eeef;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.checkout {
  width: 66px;
  height: 49px;
  background: #00389b;
  border-radius: 0 0 0 3px;
  & > svg {
    width: 34px;
    height: 34px;
    margin: 10px 0 0 15px;

    &:hover {
      cursor: pointer;
    }
  }
}

.content {
  background-color: white;
  margin: 30px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgb(0 56 155 / 15%);
  display: flex;
  justify-content: space-between;
}

.demoContent {
  justify-content: flex-start;

  & > .settings {
    margin-left: 75px;
  }
}

.profile {
  width: 30%;
  display: flex;
  flex-direction: column;

  & > h2 {
    margin-bottom: 30px;
    color: #00389b;
  }
  & > input {
    margin-bottom: 15px;
  }

  & > div {
    margin-bottom: 15px;
  }

  & > .selectSex {
    margin-bottom: 15px;
    text-align: left;
  }

  & > .twoInputs {
    display: flex;
    justify-content: space-between;

    & > input:last-of-type {
      margin-left: 10px;
    }

    & > div:last-of-type {
      margin-left: 10px;
    }
  }
}

.credentials {
  width: 30%;
  display: flex;
  flex-direction: column;

  & > h2 {
    margin-bottom: 30px;
    color: #00389b;
  }
  & > input {
    margin-bottom: 15px;
  }

  & > div {
    margin-bottom: 15px;
  }

  & > .selectSex {
    margin-bottom: 15px;
    text-align: left;
  }
}

.settings {
  width: 30%;
  display: flex;
  flex-direction: column;

  & > h2 {
    margin-bottom: 30px;
    color: #00389b;
  }

  & > div {
    margin-bottom: 15px;
  }
}

.settingsItem {
  display: flex;
  justify-content: space-between;
}

.mainMenu {
  position: relative;
  & > a > svg {
    color: #00389b;
    width: 34px;
    height: 34px;
  }

  & > a > img {
    width: 34px;
    height: 34px;
  }

  & > .menuItem {
    cursor: pointer;
    padding: 28px 34px 15px 34px;
    display: flex;
    align-items: center;

    & > span {
      margin-left: 15px;
      color: #0a0f47;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.profileSave {
  width: 30%;
  margin: 0 auto;
}

.changePassButton {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.hamburgerButton {
  width: 20px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: -30px;
}

.menuHide {
  width: 60px;

  & > .mainMenu > .menuItem {
    padding: 0;
    display: block;
    margin: 30px 0;

    &:first-of-type {
      margin-top: 70px;
    }
  }

  & > .mainMenu > .hamburgerButton {
    position: relative;
    right: 0;
    top: 50px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 769px) {
  .content {
    flex-direction: column;
  }
  .profile {
    width: 100%;
  }
  .credentials {
    width: 100%;
  }
  .settings {
    width: 100%;
  }

  .demoContent {
    .settings {
      margin-left: 0;
    }
  }
  .main {
    height: 100%;
    padding-bottom: 20px;
  }
}
