@import "../../assets/mixins.scss";

.dropdownMenuContent {
  width: 400px;
  background-color: white;
  box-shadow: -3px 5px 29px rgb(0 56 155 / 35%);
  border-radius: 0 0 5px 5px;
  padding: 13px 0 13px 25px;
  color: #00389b;
  font-size: 12.5px;
}

.dropdownUser {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  align-items: center;

  & > div {
    display: flex;
    justify-content: center;
  }

  & > span {
    font-size: 16.67px;
  }
}

.dropdownSettings {
  width: 22px;
  color: #00389b;
  display: flex;
  justify-content: center;
  margin-right: 5px;
  & > svg {
    fill: #00389b;
  }

  &:hover {
    cursor: pointer;
  }
}

.dropdownSignOut {
  width: 26px;
  color: #00389b;
  display: flex;
  justify-content: center;
  & > svg {
    fill: #00389b;
  }
  &:hover {
    cursor: pointer;
  }
}

.dropdownIconRotate {
  -webkit-animation: spin 0.2s normal forwards ease-in-out;
  -moz-animation: spin 0.2s normal forwards ease-in-out;
  animation: spin 0.2s normal forwards ease-in-out;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(180deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(180deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.dropdownPanel {
  width: 400px;
  box-shadow: -3px 5px 29px rgb(0 56 155 / 35%);
  border-radius: 0 0 5px 5px;

  & > ul {
    padding: 13px 0 0 25px;
  }
}

.dropdownButton {
  width: 16px;
  height: 16px;
  background: #01419b;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #47bcf6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 20px 0 0;

  & > span {
    text-align: center;
  }
}

.topMenu {
  background: linear-gradient(to right, #005a9d 0%, #47bcf6 100%) no-repeat;
  border-radius: 0 0 0 10px;
  height: 49px;
  width: calc(100% + 40px);
  margin-left: -40px;
  display: flex;
  justify-content: flex-end;
}
