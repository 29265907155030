@import "../../assets/mixins.scss";

.button {
  @include InterMedium;
  font-size: 16px;
  color: $white;
  background-color: $mainBlue;
  padding: 18px;
  border-radius: 8px;
  outline: none;
  border: none;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

.disabled {
  background-color: $lightBlue;
  pointer-events: none;
}
