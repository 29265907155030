@import "../../assets/mixins.scss";
.datepicker {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $lightBlue;
  background: $lightBlue;
  font-size: 16px;
  width: 100%;

  &:focus {
    border-color: transparent !important;
    outline: 0;
    box-shadow: none !important;
  }

  &:hover {
    border-color: transparent !important;
    box-shadow: none;
  }

  input {
    @include InterMedium;
    outline: none;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $grayBlue;
      font-size: 16px;
    }

    &:hover {
      border-color: transparent !important;
      box-shadow: none;
    }

    &:focus {
      border-color: transparent !important;
      outline: 0;
      box-shadow: none;
    }
  }
}
