@import "../../assets/mixins.scss";

.textfield {
  @include InterMedium;
  padding: 16px;
  background: $lightBlue;
  border-radius: 8px;
  border: 1px solid $lightBlue;
  font-size: 16px;
  outline: none;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $grayBlue;
  }

  &:hover {
    border-color: transparent !important;
    box-shadow: none;
  }

  &:focus {
    border-color: transparent !important;
    outline: 0;
    box-shadow: none;
  }
}

.error {
  background-color: $pink;
  border: 1px solid $red;
  color: $red;

  &:focus {
    border-color: $red;
    outline: 0;
    box-shadow: none;
  }

  &:hover {
    border-color: $red;
    box-shadow: none;
  }
}

.textfield {
  & > input {
    @include InterMedium;
    padding: 16px;
    background: $lightBlue;
    border-radius: 8px;
    border: 1px solid $lightBlue;
    font-size: 16px;
    outline: none;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $grayBlue;
    }

    &:hover {
      border-color: transparent !important;
      box-shadow: none;
    }

    &:focus {
      border-color: transparent !important;
      outline: 0;
      box-shadow: none;
    }
  }
}

.error {
  & > input {
    background-color: $pink;
    border: 1px solid $red;
    color: $red;
  }
  &:focus {
    border-color: $red;
    outline: 0;
    box-shadow: none;
  }

  &:hover {
    border-color: $red;
    box-shadow: none;
  }
}
