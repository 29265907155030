@font-face {
  font-family: "Inter-Regular";
  src: url("./fonts/Inter-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Inter-Medium";
  src: url("./fonts/Inter-Medium.ttf") format("ttf");
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("./fonts/Inter-SemiBold.ttf") format("ttf");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("./fonts/Inter-Bold.ttf") format("ttf");
}
