@import "../../assets/mixins.scss";

.menu {
  width: 270px;
  text-align: center;
  padding: 15px 0;
  & > svg {
    width: 82px;
  }
}




.dropdownButton {
  width: 16px;
  height: 16px;
  background: #01419b;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #47bcf6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 20px 0 0;

  & > span {
    text-align: center;
  }
}

.dropdownIconRotate {
  -webkit-animation: spin 0.2s normal forwards ease-in-out;
  -moz-animation: spin 0.2s normal forwards ease-in-out;
  animation: spin 0.2s normal forwards ease-in-out;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(180deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(180deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.dropdownPanel {
  width: 400px;
  box-shadow: -3px 5px 29px rgb(0 56 155 / 35%);
  border-radius: 0 0 5px 5px;

  & > ul {
    padding: 13px 0 0 25px;
  }
}

.dropdownMenuContent {
  width: 400px;
  background-color: white;
  box-shadow: -3px 5px 29px rgb(0 56 155 / 35%);
  border-radius: 0 0 5px 5px;
  padding: 13px 0 13px 25px;
  color: #00389b;
  font-size: 12.5px;
}

.mainMenu {
  position: relative;
  & > a > svg {
    color: #00389b;
    width: 34px;
    height: 34px;
  }

  & > a > img {
    width: 34px;
    height: 34px;
  }

  & > .menuItem {
    cursor: pointer;
    padding: 28px 34px 15px 34px;
    display: flex;
    align-items: center;

    & > span {
      margin-left: 15px;
      color: #0a0f47;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.hamburgerButton {
  width: 20px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: -30px;
}

.menuHide {
  width: 60px;

  & > .mainMenu > .menuItem {
    padding: 0;
    display: block;
    margin: 30px 0;

    &:first-of-type {
      margin-top: 70px;
    }
  }

  & > .mainMenu > .hamburgerButton {
    position: relative;
    right: 0;
    top: 50px;
    margin: 0 auto;
  }
}
