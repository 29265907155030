@import "../../assets/mixins.scss";

.wrapper {
  display: flex;
}

.main {
  background: #e8eeef;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  padding: 30px;
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.goals {
  display: flex;
  flex-direction: column;
  width: 47%;
  justify-content: space-between;
  background-color: $white;
  border-radius: 15px;
  padding: 15px;

  & > h3 {
    @include InterMedium;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
    margin-bottom: 15px;
  }

  & > .goalItem {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    & > .circle {
      border-radius: 50%;
      background-color: #f9f9fe;
      width: 40px;
      height: 40px;
    }

    & > .goalContent {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      & > h4 {
        @include InterRegular;
        font-size: 18px;
        margin-bottom: 0;
      }
    }
  }
}

.progress {
  width: 350px;
  background-color: #f2f7fe;
  border-radius: 20px;
  height: 10px;
  margin-top: 5px;

  & > .active {
    background-color: #1c499b;
    height: 10px;
    border-radius: 20px;
  }
}

.biomarkers {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 15px;

  & > .biomarkersHeader {
    display: flex;
    justify-content: space-between;
    & > div {
      display: flex;
      flex-direction: column;
      & > h3 {
        @include InterMedium;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      & > span {
        @include InterRegular;
        margin-top: 10px;
      }
    }
  }
}

.schedule {
  display: flex;
  width: 50%;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 15px;
  flex-direction: column;

  & > h3 {
    @include InterMedium;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  & > .event {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f3f6fb;
    align-items: center;

    & > span {
      @include InterMedium;
      font-size: 20px;
    }

    & > .eventType {
      display: flex;
      flex-direction: column;
      padding: 10px 0;

      & > h4 {
        @include InterMedium;
        color: #1e4a9c;
        font-size: 22px;
        margin-bottom: 0;
      }

      & > span {
        @include InterRegular;
        font-size: 12px;
      }
    }
  }
}

.recommendations {
  display: flex;
  width: 47%;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 15px;
  flex-direction: column;

  & > h3 {
    @include InterMedium;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .timeline {
    display: flex;
    flex-direction: column;
    border-left: 2px solid #eee;

    .timelineItem {
      display: flex;
      flex-direction: column;
      position: relative;

      & > .item {
        display: flex;
        align-items: center;
        margin-left: 15px;
        margin-top: 10px;
        width: 50%;
        padding: 15px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 4px 22px 0px rgba(34, 60, 80, 0.2);

        & > span {
          @include InterRegular;
          font-size: 14px;
          margin-left: 20px;
        }
      }

      & > span {
        padding-left: 15px;
        margin-top: 15px;

        & > span.circle {
          background-color: #1c499b;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          left: -5.5px;
          position: absolute;
          margin-top: 5px;
        }
      }
    }
  }
}

/**/

.recomendationsItem {
  padding: 15px;
  background-color: $white;
  border-radius: 15px;
  display: flex;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.recomendationContent {
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  & > h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
  }
}

.recomendationImage {
  & > svg {
    width: 100px;
    height: 100px;
  }
}

.recomendationCount {
  @include InterMedium;
  font-size: 30px;
  margin-left: auto;
  font-weight: 700;
  color: #64a467;

  & > svg {
    width: 20px;
    color: #64a467;
  }
}

.employees {
  display: flex;
  justify-content: space-between;
  width: 47%;
}

.worker {
  width: 47%;
  padding: 15px;
  background-color: $white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;

  & > h3 {
    @include InterMedium;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 16px;
  }

  & > span {
    @include InterRegular;
    font-size: 12px;
  }

  & > .photo {
    width: 90px;
    height: 90px;
    background-color: #1c499b;
    border-radius: 50%;
  }

  & > .praise {
    @include InterRegular;
    margin-top: 10px;
    padding: 0;
    width: fit-content;
    font-size: 14px;
    border-radius: 5px;
    padding: 2px 7px;
  }

  & > .indicators {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    & > .indicatorItem {
      display: flex;
      flex-direction: column;

      & > h4 {
        @include InterMedium;
        font-weight: 600;
        color: #1c499b;
        margin-top: 0;
        margin-bottom: 0;
      }

      & > span {
        @include InterRegular;
        font-size: 12px;
      }
    }
  }
}

.department {
  width: 47%;
  padding: 15px;
  background-color: $white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;

  & > .praise {
    @include InterRegular;
    margin-top: 10px;
    padding: 0;
    width: fit-content;
    font-size: 14px;
    border-radius: 5px;
    padding: 2px 7px;
  }

  & > div {
    & > h3 {
      @include InterMedium;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0;
      margin-top: 70px;
    }

    & > span {
      @include InterRegular;
      font-size: 12px;
    }
    & > .indicators {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      & > .indicatorItem {
        display: flex;
        flex-direction: column;

        & > h4 {
          @include InterMedium;
          font-weight: 600;
          color: #1c499b;
          margin-top: 0;
          margin-bottom: 0;
        }

        & > span {
          @include InterRegular;
          font-size: 12px;
        }
      }
    }
  }
}

.ageCount {
  @include InterMedium;
  font-size: 30px;
  margin-left: auto;
  font-weight: 700;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  color: #64a467;

  & > svg {
    width: 20px;
    color: #64a467;
    transform: rotate(180deg);
    margin-right: 10px;
  }
}
