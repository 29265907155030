@import "../../assets/mixins.scss";

.authContainer {
  background-image: url("../../assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.authForm {
  background-color: $white;
  width: 440px;
  box-shadow: 0px 1px 4px rgba(21, 40, 78, 0.1);
  border-radius: 16px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 48px;
}

.authLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.authTitle {
  @include InterBold;
  text-align: center;
  font-size: 24px;
  color: $mainBlack;
}

.authTabs {
  display: flex;
  margin: 0 auto;
  justify-items: center;
  margin-bottom: 20px;

  & > a {
    @include InterSemi;
    border-bottom: 2px solid $mainBlue;
    padding: 8px 20px 8px 20px;
    color: $mainBlack;
    font-size: 16px;
  }

  & > a.disabled {
    color: $grayBlue;
    border-bottom: 2px solid $lightBlue;
  }
}

.authSocTitle {
  @include InterRegular;
  color: $mainBlack;
  margin-top: 24px;
  margin-bottom: 8px;
}

.authInput {
  margin-bottom: 16px;
}
.authForgot {
  @include InterMedium;
  letter-spacing: 0.2px;
  text-align: center;
  color: $mainBlue;
  margin-bottom: 16px;
}

.authForgotReg {
  @include InterMedium;
  margin-top: 16px;
  letter-spacing: 0.2px;
  text-align: center;
  color: $mainBlue;
}

.authSocial {
  display: flex;
  padding: 16px;
  box-shadow: 0px 1px 4px rgba(21, 40, 78, 0.1);
  border-radius: 8px;
  margin-bottom: 8px;

  &:hover {
    cursor: pointer;
  }

  & > span {
    @include InterSemi;
    font-size: 16px;
    color: $mainBlack;
    margin-left: 8px;
  }

  & > a.socialBindLabel {
    margin-left: auto;
    color: #2559d6;
  }

  & > a.socialBindLabelRed {
    margin-left: auto;
    color: rgb(187, 48, 48);
  }
}

.authSocialDisabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.authDisclaimer {
  @include InterMedium;
  position: absolute;
  bottom: 0;
  font-size: 12px;
  color: $white;
  text-align: center;
  line-height: 15px;

  & > a {
    color: $white;
    border-bottom: 1px solid;
  }
}

.authAgree {
  & > span {
    margin-bottom: 15px;
    font-size: 12px;

    & > span > a {
      color: $mainBlue !important;
    }
  }
}

.authBackButon {
  font-size: 16px;
  color: $mainBlue;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
    color: $mainBlue;
  }
}

.agreement {
  & > label {
    margin-right: 10px;
  }
}

.authToggleSex {
  display: flex;
  margin-top: 15px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-between;

  & > span {
    @include InterMedium;
    font-size: 16px;
  }

  & > div {
    & > a.active {
      background-color: $mainBlue;
      color: $white;
      border: 1px solid $mainBlue;
    }

    & > a {
      @include InterMedium;
      font-size: 16px;
      padding: 10px 30px;
      color: $mainBlue;
      border: 1px solid $mainBlue;
      background-color: transparent;
    }

    & > a:first-of-type {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
    }

    & > a:last-of-type {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }
  }
}

.facebookButton {
  @include InterSemi;
  text-align: center;
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;

  font-weight: 600;
  font-size: 16px;
  color: #15284e;

  & > svg {
    margin-right: 8px;
  }
}

.authSocialFacebookDisabled {
  cursor: not-allowed !important;
}

.authSocialFacebook {
  display: flex;
  padding: 16px 16px 16px 8px;
  box-shadow: 0px 1px 4px rgba(21, 40, 78, 0.1);
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }

  & > span {
    @include InterSemi;
    font-size: 16px;
    color: $mainBlack;
    margin-left: 8px;
    width: 100%;
    cursor: pointer;

    & > button {
      cursor: pointer;
    }
  }

  & > .socialBindLabel {
    margin-left: auto;
    color: #2559d6;
    cursor: pointer;
  }
  & > .socialBindLabelRed {
    margin-left: auto;
    color: rgb(187, 48, 48);
  }
}

.authSocialFacebookBind {
  display: flex;
  padding: 16px 16px 16px 8px;
  box-shadow: 0px 1px 4px rgba(21, 40, 78, 0.1);
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  & > span {
    @include InterSemi;
    font-size: 16px;
    color: $mainBlack;
    margin-left: 8px;
    width: 100%;
    position: absolute;
    cursor: pointer;

    & > button {
      cursor: pointer;
      width: 94%;
      position: absolute;
    }
  }

  & > .socialBindLabel {
    margin-left: auto;
    color: #2559d6;
  }
  & > .socialBindLabelRed {
    margin-left: auto;
    color: rgb(187, 48, 48);
  }
}

.appleUserNoExist {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  & > .container {
    width: 600px;
    text-align: center;
  }

  .title {
    @include InterBold;
    font-size: 34px;
    margin-bottom: 0;
    color: $mainBlack;
    text-align: center;
    margin-bottom: 15px;
    line-height: normal;
  }

  .description {
    @include InterRegular;
    font-size: 16px;
    color: $grayBlue;
    text-align: center;
    padding: 0 40px;
    margin-bottom: 15px;
  }
}
