@import "../../assets/mixins.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.container {
  width: 600px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title {
  @include InterBold;
  font-size: 24px;
  color: $mainBlack;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: normal;
}
