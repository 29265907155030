@import "../../assets/mixins.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.container {
  width: 600px;
  text-align: center
}

.title {
  @include InterBold;
  font-size: 34px;
  margin-bottom: 0;
  color: $mainBlack;
  text-align: center;
  margin-bottom: 15px;
  line-height: normal;
}

.description {
  @include InterRegular;
  font-size: 16px;
  color: $grayBlue;
  text-align: center;
  padding: 0 40px;
  margin-bottom: 15px;
}

.input {
  padding: 0 150px;
}

.code {
  @include InterBold;
  font-size: 50px;
  padding: 2px;
  text-align: center;
}

.button {
  display: flex;
  justify-content: center;
  padding: 0 150px;
  margin-top: 15px;

  & > button {
    width: 100%;
  }
}

.userinfoInputs {
  width: 60%;
  margin: 0 auto;
  text-align: center;

  & > input {
    margin-bottom: 15px;
  }
}

.goalBlock {
  display: flex;
  border-radius: 10px;
  padding: 16px;
  font-size: 16px;
  box-shadow: 0px 1px 8px rgba(21, 40, 78, 0.1);
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}
