@import "../../assets/mixins.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.container {
  width: 600px;
  text-align: center;
}

.title {
  @include InterBold;
  font-size: 34px;
  margin-bottom: 0;
  color: $mainBlack;
  text-align: center;
  margin-bottom: 15px;
  line-height: normal;
}

.input {
  text-align: center;
  margin-bottom: 15px;
  width: 50%;
  margin: 0 auto;

  & > span {
    margin-top: 15px;
  }
}

.passBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  & > button {
    margin-top: 15px;
  }
}

.button {
  margin-top: 15px;
}
