$white: #ffffff;
$mainBlack: #15284e;
$mainBlue: #2559D6;
$lightBlue: #9EC2EC;
$pink: #ffe0df;
$red: #e35953;
$lightBlue: #edeef8;
$grayBlue: #919aaf;

@mixin InterRegular {
  font-family: "Inter-Regular", sans-serif;
  font-weight: 400;
}

@mixin InterMedium {
  font-family: "Inter-Medium", sans-serif;
  font-weight: 500;
}

@mixin InterSemi {
  font-family: "Inter-SemiBold", sans-serif;
  font-weight: 600;
}

@mixin InterBold {
  font-family: "Inter-Bold", sans-serif;
  font-weight: 700;
}
